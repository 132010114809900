<template>
  <div class="flex flex-col items-center m-5">
    <div class="space-y-2">
      <EmailButton
        v-if="suspectName && videoUrls.length > 0"
        email="" 
        :subject="subject"
        :cc="cardSharingEmailObj.cc"
        :bcc="cardSharingEmailObj.bcc"
        :body="cardSharingEmailObj.body" 
        type="mailto"
      />

      <h1 v-if="videoUrls.length == 0" class="text-red-500">Video URLs are missing</h1>      
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import EmailButton from './EmailButton.vue';
import { capitalizeFirstLetters } from '@/helpers';
import { useStateStore } from '@/stores/StateStore';

export default {
  components: {
    EmailButton,
  },
  name: "CreateCardSharingEmail",
  props: {
    suspectName: {
      type: String,
      required: true,
    },
    videoUrls:{
      type: Array,
      required: true,
      default: () => []
    },
    entryDate: {
      type: Date,
      required: true,
    },
  },
  setup(props) {
    const stateStore = useStateStore();
    const subject = computed(() => stateStore.selectedLocation);
    const cardSharingEmailObj = computed(() => {
      const cardSharingEmailTemplate = stateStore.getCardSharingEmailTemplate();   
      const body = populateBodyContentVariables(cardSharingEmailTemplate.body);
      const newObj = {
        "cc": cardSharingEmailTemplate.cc,
        "bcc": cardSharingEmailTemplate.bcc,
        "body": body,
      }
      return newObj;
    });
    
    const populateBodyContentVariables = (bodyContent) => {
      let newBodyContent = bodyContent;
      if (props.suspectName) {        
        let eventDate = "<date>";
        if (props.entryDate) {
          eventDate = prettyDate(props.entryDate);
        }

        try {
          newBodyContent = newBodyContent.replace('<name>', capitalizeFirstLetters(props.suspectName));
          newBodyContent = newBodyContent.replace('<location>', stateStore.selectedLocation);
          newBodyContent = newBodyContent.replace('<date>', eventDate);
          
          let videos = "";
          for (const videoUrl of props.videoUrls) {
            videos = videos + `${videoUrl}\n\n`
          }
          newBodyContent = newBodyContent.replace('<video>', videos);
        } catch (error) {
          console.warn("email body is missing variables")
        }
      }
      return newBodyContent
    }
    const prettyDate = (date) => {
      return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
      }).format(date);
    }

    return {
      subject,
      cardSharingEmailObj
    }
  }
};
</script>
