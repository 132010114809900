const stripeApiKey = process.env.VUE_APP_STRIPE_API_KEY;
const stripe = require('stripe')(stripeApiKey);
const createStripeCustomer = async (name, email, phone) => {
    const customer = await stripe.customers.create({
        name: name,
        email: email,
        phone: phone
    }
    );
    console.log("Created stripe customer");
    return customer.id;
}

function binarySearchForInsertion(arr, target, compareFunc) {
    let left = 0;
    let right = arr.length - 1;

    while (left <= right) {
        let mid = Math.floor((left + right) / 2);

        // Compare datetime values using the comparison function
        let comparisonResult = compareFunc(arr[mid], target);

        if (comparisonResult < 0) {
            left = mid + 1;
        } else {
            right = mid - 1;
        }
    }

    // At the end of the loop, 'left' indicates the index where the new element should be inserted
    return left;
}

import { createSignedUrl } from '@/S3Bucket';
import { getFirebaseUrl } from '@/firebase';
// Get video url
const getVideoUrl = async (vidPath, bucket=null) => {
    let url = null;

    try {
      // New Standard
      url = await createSignedUrl(bucket, vidPath);
      if (url == null) {
        throw "Error creating signed url"
      }
      // console.log("new storage")
    } catch {
      // Old standard
      const firebaseUrl = await getFirebaseUrl(vidPath);
      url = firebaseUrl;
      // console.warn("old storage")
    }

    if (url == null) {
      throw new Error('Error getting video download URL')
    }

    return url
  };

// Comparison function to compare datetime values
function compareFirestoreTimestamps(obj1, obj2) {
    return obj1.entry_time.toDate() - obj2.entry_time.toDate();
}

function insertFlaggedVid(sortedArray, newObj) {
    // Find the index where the new object should be inserted
    const insertIndex = binarySearchForInsertion(sortedArray, newObj, compareFirestoreTimestamps);
    sortedArray.splice(insertIndex, 0, newObj);
}

function getDatetimeBeforeAndAfter(dateTime, secondsHalfRange) {
    // Clone the input datetime to avoid modifying the original
    const beforeDateTime = new Date(dateTime);
    const afterDateTime = new Date(dateTime);

    // Calculate 5 minutes in milliseconds
    const millisecondsHalfRange = secondsHalfRange * 1000;

    // Adjust before and after datetime objects
    beforeDateTime.setTime(beforeDateTime.getTime() - millisecondsHalfRange);
    afterDateTime.setTime(afterDateTime.getTime() + millisecondsHalfRange);

    return { before: beforeDateTime, after: afterDateTime };
}

const capitalizeFirstLetters = (str) => {
return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const sortTimstampsFromSuspectMetadata = (suspectsMetadata) => {
    let sortedTimestamps;
    let timestamps = Object.keys(suspectsMetadata)
    if(timestamps.length > 0) {
      // Sorts timestamps in ascending order.
      sortedTimestamps = timestamps.sort((a, b) => {
        const secondsA = parseInt(a.match(/seconds=(\d+)/)[1], 10);
        const secondsB = parseInt(b.match(/seconds=(\d+)/)[1], 10);

        return secondsA - secondsB;
      })
    }

    return Object.values(sortedTimestamps);
}

const sortScanEvents = (suspectsMetadata) => {
  const scanEvents = [];
  if (suspectsMetadata) {
    if (Object.keys(suspectsMetadata).length > 0) {
      const sortedTimstamps = sortTimstampsFromSuspectMetadata(suspectsMetadata);
      for(const timestamp of sortedTimstamps) {
        const event = suspectsMetadata[timestamp];
        scanEvents.push({
          "name": event.name,
          "scan_time": event.scan_time
        });
      }
    }    
  }

  return scanEvents;
}

export {
    createStripeCustomer,
    getVideoUrl,
    insertFlaggedVid,
    getDatetimeBeforeAndAfter,
    capitalizeFirstLetters,
    sortScanEvents
}