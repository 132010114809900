<template>
  <ModalComponent openButtonName="Custom Email Template" :onModalClose="modalClosed">
    <template v-slot:openButton="{ onButtonClick }">
      <button @click="onButtonClick"
              v-on:click="modalOpened"
              class="flex flex-col items-center bg-transparent hover:text-teal-200 text-blue-700 font-semibold hover:border-teal-200 rounded" >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3,10 L16,18 L29,10"/>
          <path stroke-linecap="round" stroke-linejoin="round" d="M7,12.5V3c0-1.1,0.9-2,2-2h14c1.1,0,2,0.9,2,2v9.5"/>
          <path stroke-linecap="round" stroke-linejoin="round" d="M25,5c2.2,0,4,1.8,4,4v14c0,2.2-1.8,4-4,4H7c-2.2,0-4-1.8-4-4V9c0-2.2,1.8-4,4-4"/>
          <path stroke-linecap="round" stroke-linejoin="round" d="M11,5 L18,5"/>
          <path stroke-linecap="round" stroke-linejoin="round" d="M11,9 L14,9"/>
        </svg>
      </button>
    </template>

    <template v-slot:body>
      <div class="space-y-10">
        <div class="space-y-3">
          <h1 class="text-lg font-bold">Tailgating Email Template</h1>
          <ReusableForm
            class="w-screen"
            :fields="tailgatingFormFields"
            :submitButtonText="submitTailgatingButtonText"
            @submit="handleTailgatingFormSubmit"
          />        
        </div>

        <div class="space-y-3">
          <h1 class="text-lg font-bold">Multi Entry Email Template</h1>
          <ReusableForm
            class="w-screen"
            :fields="cardSharingFormFields"
            :submitButtonText="submitCardSharingButtonText"
            @submit="handleCardSharingFormSubmit"
          />        
        </div>        
      </div>
    </template>
  </ModalComponent>   
</template>

<script>
import { ref, computed } from 'vue';
import ModalComponent from '../ModalComponent.vue';
import ReusableForm from '../ReusableForm.vue';
import { useStateStore } from '@/stores/StateStore';
import { updateTailgatingEmailTemplate, getTailgatingEmailTemplate, updateCardSharingEmailTemplate } from '@/firebase';

export default {
  components: {
    ModalComponent,
    ReusableForm,
  },
  name: "CustomEmailTemplate",
  props: {
  },
  setup() {
    const stateStore = useStateStore();
    const mode = ref("Tailgating Template");
    const defaultTailgatingEmailBody = `Dear <name>,

Our security team has noted that you accessed <location> on <date> accompanied by another individual. 

As this person did not check in using their access card nor app, would you please provide their name and mobile number? 

For your reference, you may review the CCTV footage here:
<video>`
    const defaultCardSharingEmailBody = `Dear <name>,

Our security team has noted that your card was used by multiple individuals at <location> on <date>. 

As this person did not check in using their access card nor app, would you please provide their name and mobile number? 

For your reference, you may review the CCTV footage here:
<video>`

    const tailgatingFormFields = computed(() => {
      let tailgatingEmailTemplate = stateStore.getTailgatingEmailTemplate();

      let fields = [
        { name: "cc", label: "CC", type: "text", placeholder: "Enter CC", default: tailgatingEmailTemplate ? tailgatingEmailTemplate.cc : ""},
        { name: "bcc", label: "BCC", type: "text", placeholder: "Enter BCC", default: tailgatingEmailTemplate ? tailgatingEmailTemplate.bcc : ""},
        { name: "body", label: "Body", type: "textarea", placeholder: "Enter email body", default: tailgatingEmailTemplate ? tailgatingEmailTemplate.body : defaultTailgatingEmailBody},
      ]

      return fields;
    });
    const cardSharingFormFields = computed(() => {
      let cardSharingEmailTemplate = stateStore.getCardSharingEmailTemplate();

      let fields = [
        { name: "cc", label: "CC", type: "text", placeholder: "Enter CC", default: cardSharingEmailTemplate ? cardSharingEmailTemplate.cc : ""},
        { name: "bcc", label: "BCC", type: "text", placeholder: "Enter BCC", default: cardSharingEmailTemplate ? cardSharingEmailTemplate.bcc : ""},
        { name: "body", label: "Body", type: "textarea", placeholder: "Enter email body", default: cardSharingEmailTemplate ? cardSharingEmailTemplate.body : defaultCardSharingEmailBody},
      ]

      return fields;
    });

    const submitTailgatingButtonText = ref("Save");
    const handleTailgatingFormSubmit = async(formData) => {
      submitTailgatingButtonText.value = "Saving Template..."

      stateStore.setTailgatingEmailTemplate(formData);
      await updateTailgatingEmailTemplate(stateStore.getSelectedLocationId(), formData);

      submitTailgatingButtonText.value = "Template Saved!"
      setTimeout(() => {submitTailgatingButtonText.value = "Save"}, 2000);
    };

    const submitCardSharingButtonText = ref("Save");
    const handleCardSharingFormSubmit = async(formData) => {
      submitCardSharingButtonText.value = "Saving Template..."

      stateStore.setCardSharingEmailTemplate(formData);
      await updateCardSharingEmailTemplate(stateStore.getSelectedLocationId(), formData);

      submitCardSharingButtonText.value = "Template Saved!"
      setTimeout(() => {submitCardSharingButtonText.value = "Save"}, 2000);
    };

    const onTailgatingClick = () => {
      mode.value = "Tailgating Template";
    }
    const onCardSharingClick = () => {
      mode.value = "Multi Entry Template";
    }

    const modalOpened = async () => {
      // Update from firestore
      const tailgatingEmailTemplate = await getTailgatingEmailTemplate(stateStore.getSelectedLocationId());
      stateStore.setTailgatingEmailTemplate(tailgatingEmailTemplate);
    }
    const modalClosed = () => {}
    
    return {
      tailgatingFormFields,
      cardSharingFormFields,

      submitTailgatingButtonText,
      handleTailgatingFormSubmit,
      submitCardSharingButtonText,
      handleCardSharingFormSubmit,
      mode,
      onTailgatingClick,
      onCardSharingClick,
      modalOpened,
      modalClosed
    }
  }
};
</script>
