<template>
  <div v-if="visible" class="flex flex-col p-5 space-y-2">
    <div class="space-x-2">
      <SearchableDropdown
        message="Select Member to Email"
        :options="suspectNames" 
        v-model="selectedSuspectName"
      />

      <EmailButton
        v-if="selectedSuspectName!= null && videoUrl"
        email="" 
        :subject="subject"
        :cc="tailgatingEmailObj.cc"
        :bcc="tailgatingEmailObj.bcc"
        :body="tailgatingEmailObj.body" 
        type="mailto"
      />      
    </div>

    <h1 v-if="!videoUrl" class="text-red-500">Video URL is broken</h1>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import EmailButton from './EmailButton.vue';
import { capitalizeFirstLetters, sortScanEvents } from '@/helpers';
import { useStateStore } from '@/stores/StateStore';
import SearchableDropdown from '../../components/dropdown/SearchableDropdown.vue'

export default {
  components: {
    EmailButton,
    SearchableDropdown
  },
  name: "CreateTailgatingEmail",
  props: {
    videoUrl:{
      type: String,
      required: true
    },
    scanEvents: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const visible = ref(true);
    const selectedSuspectName = ref();
    const suspectNames = ref();
    onMounted(async () => {
      if (props.scanEvents) {
        suspectNames.value = Object.values(props.scanEvents).map(suspect => ({
          label: suspect.name,
          value: suspect.name,
        }));
        visible.value = suspectNames.value.length > 0;        
      } else {
        visible.value = false;
      }
    });

    const stateStore = useStateStore();
    const subject = computed(() => stateStore.selectedLocation);
    const tailgatingEmailObj = computed(() => {
      const tailgatingEmailTemplate = stateStore.getTailgatingEmailTemplate();
      const body = populateBodyContentVariables(tailgatingEmailTemplate.body);
      const newObj = {
        "cc": tailgatingEmailTemplate.cc,
        "bcc": tailgatingEmailTemplate.bcc,
        "body": body,
      }
      return newObj;
    });
    
    const populateBodyContentVariables = (bodyContent) => {
      let newBodyContent = bodyContent;
      if (selectedSuspectName.value) {
        const sortedScanEvents = sortScanEvents(props.scanEvents);
        
        let eventDate = "<date>";
        for (const event of sortedScanEvents) {
          if (event.name == selectedSuspectName.value) {
            eventDate = prettyDate(event.scan_time.toDate())
            break;
          }
        }

        try {
          newBodyContent = newBodyContent.replace('<name>', capitalizeFirstLetters(selectedSuspectName.value));
          newBodyContent = newBodyContent.replace('<location>', stateStore.selectedLocation);
          newBodyContent = newBodyContent.replace('<date>', eventDate);
          newBodyContent = newBodyContent.replace('<video>', props.videoUrl);
        } catch (error) {
          console.warn("email body is missing variables")
        }
      }
      return newBodyContent
    }
    const prettyDate = (date) => {
      return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).format(date);
    }

    return {
      visible,
      selectedSuspectName,
      suspectNames,
      subject,
      tailgatingEmailObj
    }
  }
};
</script>
