<template>
  <button 
    @click="sendEmail" 
    class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
  >
    {{ buttonLabel }}
  </button>
</template>

<script>
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "EmailButton",
  props: {
    email: {
      type: String,
      required: true
    },
    cc: {
      type: String,
      default: null
    },
    bcc: {
      type: String,
      default: null
    },
    subject: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "mailto", // Default to "mailto"
      validator: (value) => ["mailto", "gmail", "outlook"].includes(value)
    }
  },
  setup(props) {
    const sendEmail = () => {
      const { email, subject, body, type } = props;

      const encodedSubject = encodeURIComponent(subject);
      const encodedBody = encodeURIComponent(body);

      if (type === "mailto") {
        const mailtoLink = transformLink(`mailto:${email}?subject=${encodedSubject}&body=${encodedBody}`);
        window.location.href = mailtoLink;
      } else if (type === "gmail") {
        const gmailLink = transformLink(`https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodedSubject}&body=${encodedBody}`);
        window.open(gmailLink, "_blank");
      } else if (type === "outlook") {
        const outlookLink = transformLink(`https://outlook.office.com/mail/deeplink/compose?to=${email}&subject=${encodedSubject}&body=${encodedBody}`);
        window.open(outlookLink, "_blank");
      }
    };

    const transformLink = (mailtoLink) => {
        if (props.cc) mailtoLink += `&cc=${encodeURIComponent(props.cc)}`;
        if (props.bcc) mailtoLink += `&bcc=${encodeURIComponent(props.bcc)}`;
        return mailtoLink;
    }

    const buttonClass = computed(() => {
      switch (props.type) {
        case "gmail":
          return "bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600";
        case "outlook":
          return "bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600";
        default: // "mailto"
          return "bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600";
      }
    });

    const buttonLabel = computed(() => {
      switch (props.type) {
        case "gmail":
          return "Create Email [Gmail]";
        case "outlook":
          return "Create Email [Outlook]";
        default: // "mailto"
          return "Create Email";
      }
    });

    return {
      sendEmail,
      buttonClass,
      buttonLabel
    };
  }
});
</script>