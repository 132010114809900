<template>
  <div class="relative inline-block" ref="dropdownRef">
    <button 
      @click="toggleDropdown" 
      class="bg-gray-200 text-gray-700 rounded-md shadow-sm focus:bg-gray-300 outline-none"
    >
      <input
        ref="inputBox"
        class="bg-gray-200 text-gray-700 py-2 px-4 rounded-md shadow-sm focus:bg-gray-300 outline-none"
        type="text"
        v-model="searchQuery"
        @click="clearInput"
        @input="filterOptions"
        :placeholder="selectedLabel"
      />
    </button>
    <ul 
      v-if="isOpen" 
      class="absolute z-10 mt-2 bg-white border border-gray-300 rounded-md shadow-lg"
    >
      <li 
        v-for="option in filteredOptions" 
        :key="option.value" 
        @click="selectOption(option)"
        class="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
      >
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue';

export default {
  name: 'SearchableDropdown',
  props: {
    message: {
      type: String,
      default: "Select"
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    modelValue: {
      type: [String, Number, Object],
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const isOpen = ref(false);
    const selectedLabel = ref('');
    const dropdownRef = ref(null);

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };
    const openDropdown = () => {
      isOpen.value = true;
    };

    const selectOption = (option) => {
      emit('update:modelValue', option.value);
      searchQuery.value = option.label;
      isOpen.value = false;
    };

    const updateSelectedLabel = () => {
      const selectedOption = props.options.find(
        (option) => option.value === props.modelValue
      );
      selectedLabel.value = selectedOption ? selectedOption.label : props.message;
    };

    // Filter options based on search query
    const searchQuery = ref('');
    const filteredOptions = computed(() => {
      openDropdown()
      const newOptions = props.options.filter(option =>
        option.label.toLowerCase().includes(searchQuery.value.toLowerCase())
      );

      // Autoselect if filtered options from the search only has 1 result. 
      // Do not apply if options originally only had 1 result
      if (newOptions.length == 1 && props.options.length > 1) {
        selectOption(newOptions[0])
        removeFocus();
      }
      return newOptions;
    });

    const inputBox = ref(null);
    const removeFocus = () => {
      if (inputBox.value) {
        inputBox.value.blur(); // Programmatically lose focus
      }
    };
    const clearInput = () => {
      console.log("Clearing input")
      selectedLabel.value = props.message;
      searchQuery.value = '';
    }

    const handleClickOutside = (event) => {
      if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
        isOpen.value = false;
      }
    };

    watch(() => props.modelValue, updateSelectedLabel);
    watch(() => props.options, () => {
      if (props.options.length === 1) {
        selectOption(props.options[0])
      }
    });

    onMounted(() => {
      updateSelectedLabel();
      document.addEventListener('click', handleClickOutside);
    });

    return {
      inputBox,
      isOpen,
      selectedLabel,
      toggleDropdown,
      selectOption,
      dropdownRef,
      searchQuery,
      filteredOptions,
      clearInput,
    };
  },
};
</script>
