import { defineStore } from 'pinia';
import { useLocalStorage } from "@vueuse/core"

export const useStateStore = defineStore('stateStore', {
    state: () => ({
        selectedLocation: useLocalStorage('selectedLocation', null),
        userLocations: useLocalStorage('userLocations', {}),
        playbackSpeed: useLocalStorage('playbackSpeed', "1"),
        notificationTokenLastUpdated: useLocalStorage('notificationTokenLastUpdated', {}),
        tailgatingEmailTemplate: useLocalStorage('tailgatingEmailTemplate', {}),
        cardSharingEmailTemplate: useLocalStorage('cardSharingEmailTemplate', {}),
    }),
    actions: {
        appendToUserLocations(name, locationId) {
            this.userLocations[name] = locationId
        },
        setUserLocations(locationsObject){
            this.userLocations = locationsObject;
        },
        clearStore(){
            this.zone = 0;
            this.selectedLocation = null;
            this.userLocations = {};
        },
        getSelectedLocationId(){
            return this.userLocations[this.selectedLocation];
        },
        updateNotificationToken(uid){
            const currDate = new Date();
            this.notificationTokenLastUpdated[uid] = currDate.toString();
        },
        lastUpdateNotificationToken(uid){
            try {
                const lastUpdated = new Date(this.notificationTokenLastUpdated[uid]);
                if (!isNaN(lastUpdated)) {
                    return lastUpdated
                }
                else {
                    return false;
                }
            }
            catch {
                return false;
            }

        },
        setTailgatingEmailTemplate(emailObject){
            const locationId = this.getSelectedLocationId();
            this.tailgatingEmailTemplate[locationId] = emailObject;

            // const defaultEmailBody = `Dear <name>,

            // Our security team has noted that you accessed <location> on <date> accompanied by another individual. 
            
            // As this person did not check in using their access card nor app, would you please provide their name and mobile number? 
            
            // For your reference, you may review the CCTV footage here:
            // <video>`

            // this.tailgatingEmailTemplate = {
            //     "cc": emailObject.cc == "" ? null : emailObject.cc,
            //     "bcc": emailObject.bcc == "" ? null : emailObject.bcc,
            //     "body": emailObject.body == "" ? defaultEmailBody : emailObject.body,
            // };
        },
        getTailgatingEmailTemplate(){
            const locationId = this.getSelectedLocationId();
            if (locationId in this.tailgatingEmailTemplate) {
                return this.tailgatingEmailTemplate[locationId];
            }
            return null;
        },
        setCardSharingEmailTemplate(emailObject){
            const locationId = this.getSelectedLocationId();
            this.cardSharingEmailTemplate[locationId] = emailObject;
        },
        getCardSharingEmailTemplate(){
            const locationId = this.getSelectedLocationId();
            if (locationId in this.cardSharingEmailTemplate) {
                return this.cardSharingEmailTemplate[locationId];
            }
            return null;
        }
    },  
})

