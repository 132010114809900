<template>
  <div v-if="showSuspect" class="flex flex-col items-center">
    <VideoPlayer
    :vidPath="vidPath"
    :metadata="metadata"
    :width="width" 
    :height="height"
    ></VideoPlayer>
    
    <CardInfoTable
    :suspects="metadata.suspects"
    :showTip="index==0"
    ></CardInfoTable>

    <HandleResponse v-if="!metadata.userReviewed"
    message="Unauthorised Entry?"
    :handleYes="handleYes"
    :handleNo="handleNo"
    ></HandleResponse>
  
    <CreateTailgatingEmail v-if="metadata.userReviewed && vidUrl && metadata.suspects"
    :videoUrl="`${vidUrl}`"
    :scanEvents="metadata.suspects"
    />

  </div>

</template>

<script>
import { ref, onMounted } from "vue";
import {
  setDoc,
} from "firebase/firestore";

import VideoPlayer from '../VideoPlayer.vue';
import HandleResponse from '../HandleResponse.vue';
import CardInfoTable from '../CardInfoTable.vue';
import CreateTailgatingEmail from '../email/CreateTailgatingEmail.vue';
import { getVideoUrl } from '@/helpers';

export default {
  props: ['index', 'videoId', 'vidPath', 'metadata', 'width', 'height', 'removeVidFromView'],
  components: {
    VideoPlayer,
    HandleResponse,
    CardInfoTable,
    CreateTailgatingEmail,
  },
  setup(props) {
    const showSuspect = ref(true);

    const handleYes = async () => {
      await setDoc(props.metadata.docRef, { flagged: true }, { merge: true });
      await setDoc(props.metadata.docRef, { user_reviewed: true }, { merge: true });
    }
    const handleNo = async () => {
      await setDoc(props.metadata.docRef, { flagged: false }, { merge: true });
      await setDoc(props.metadata.docRef, { user_reviewed: true }, { merge: true });

      props.removeVidFromView(props.vidPath, props.videoId);
      showSuspect.value = false;
    }

    const vidUrl = ref();
    onMounted(async () => {
      vidUrl.value = await getVideoUrl(props.vidPath, props.metadata.b2_bucket);
    });

    return {
      showSuspect,
      handleYes,
      handleNo,
      vidUrl,
    }
  }
};
</script>