<template>
  <div>
    <AdminNavBar />
    <div class="bg-theme-tertiary mx-5">
      <h1 class="font-bold font-serif text-5xl">Admin page</h1>

      <button class="font-semibold text-xl underline text-gray-400" @click="showLocationDetails=!showLocationDetails">View/Edit Location Details</button>
      <section v-if="showLocationDetails" class="ml-3 mt-6">
        <ClientLocationsDropdown :handleSelect="changeLocation" class="max-w-sm mt-3"></ClientLocationsDropdown>
        <div class="flex flex-col gap-3">
          <div>
            <h1 class="text-xl font-semibold">{{ slLocationName }}</h1>
            <h1 class="ml-3">ID: {{ slLocationId }}</h1>
            <h1 class="ml-3">Franchise: {{ slFranchise }}</h1>
          </div>


          <h1 class="text-xl font-semibold">Address:</h1>
          <div  v-if="slAddress"
                class="ml-3">
            <h1>Line 1: {{ slAddress.line1 }}</h1>
            <h1>City: {{ slAddress.city }}</h1>
            <h1>Postal Code: {{ slAddress.postalCode }}</h1>
            <h1>State/ Region: {{ slAddress.region }}</h1>
            <h1>Country: {{ slAddress.country }}</h1>          
          </div>


          <h1 class="text-xl font-semibold"> Users:</h1>
          <div class="flex flex-col gap-3 ml-3">
            <div v-for="value, key in slUsers" :key="key">
              <h1 class="font-semibold">{{value.id}}</h1>
              <div class="ml-3">
                <h1>role: {{value.role}}</h1>
                <h1>name: {{value.name}}</h1>
                <h1>email: {{value.email}}</h1>            
              </div>
            </div>          
          </div>


          <h1 class="text-xl font-semibold"> Camera:</h1>
          <div v-if="slAreas && slAreasId" class="flex flex-col gap-3 ml-3">
            <div v-for="value, key in slAreasId" :key="key">
              <h1 class="underline">{{ key }}</h1>
              <h1>{{ value }}: {{slAreas[value]}}</h1>
            </div>          
          </div>
            
        </div>
      </section>

      <!-- <section class="mt-6">
        <NewLocationForm></NewLocationForm>
      </section> -->

      <section class="mt-6">
        <NewAccountForm></NewAccountForm>
      </section>

      <section class="mt-6">
        <p class="font-semibold text-xl underline text-gray-400">Get User details</p>
        <div class="flex">
          <form class="mx-1 my-3">
            <input class="text-black mx-3" type="email" placeholder="User Email" v-model="findDetailsForUserEmail" required/>
          </form>
          <button 
              @click="getUserDetailsForEmail"
              class="btn-primary my-1">
              Get Details
          </button>
        </div>

        <div v-if="detailsForUserEmail">
          <h1>ID: {{detailsForUserEmail.id}}</h1>
          <h1>name: {{detailsForUserEmail.name}}</h1>
          <h1>Custom Claims: {{detailsForUserEmail.custom_claims}}</h1>
        </div>
        <!-- <p>Payment status: </p>
        <p>Locations: </p>       -->
      </section>

      <section class="mt-6">
        <p class="font-semibold text-xl underline text-gray-400">Link users to locations</p>
        <div>
          <form class="mx-1 my-3 flex flex-col gap-3 max-w-min">
            <input class="text-black mx-3" type="text" placeholder="Role" v-model="userRoleLocationLinking" required/>
            <input class="text-black mx-3" type="text" placeholder="User ID" v-model="userIdForLocationLinking" required/>
            <input class="text-black mx-3" type="text" placeholder="Enter Location ID" v-model="locationIdForLinking" required/>
          </form>
          <h1 v-if="connectingUserToLocation">Connecting user...</h1>
          <h1 v-if="connectedUserToLocation">User connected to location!</h1>
          <button 
              @click="connectUserLocation"
              class="btn-primary my-1">
              Connect User and Location
          </button>
        </div>        
      </section>

    </div>
  </div>
</template>

<script>
import AdminNavBar from "@/components/AdminNavBar";
import NewAccountForm from  '@/components/adminTools/NewAccountForm.vue'
// import NewLocationForm from  '@/components/adminTools/NewLocationForm.vue'

import {getLocations, getLocationMembers, getLocationDetails} from '@/firebase';
import ClientLocationsDropdown from '@/components/dropdown/ClientLocationsDropdown.vue';
import { getFunctions, httpsCallable } from "firebase/functions";

import { addUserLocation, getUserDetailsFromEmail } from "@/firebase"
import { ref, onMounted } from 'vue';

export default {
  components: { 
    AdminNavBar,
    NewAccountForm,
    // NewLocationForm,
    ClientLocationsDropdown,
  },
  setup() {
    const showLocationDetails = ref(true);

    const locations = ref({});
    const userEmail = ref();
    const locationId = ref();
    const zoneInput = ref();

    // Selected Location details
    const slLocationId = ref('');
    const slLocationName = ref('');
    const slFranchise = ref('');
    const slAddress = ref({});

    const slAreas = ref({});
    const slAreasId = ref({});

    const slUsers = ref({});


    const changeLocation = async(location) => {  
      const locationId = locations.value[location]
      slUsers.value = await getLocationMembers(locationId);
      const locationResults = await getLocationDetails(locationId);
      if (locationResults) {
        const locationDetails = locationResults.data
        
        slLocationId.value = locationDetails.locationId
        slLocationName.value = locationDetails.name
        // slUsers.value = locationDetails.users
        slFranchise.value = locationDetails.franchise
        slAddress.value = locationDetails.address

        slAreas.value = locationDetails.door_id_to_areas
        slAreasId.value = locationDetails.door_name_to_id

        // console.log(`
        //   ${slLocationName.value}, \n
        //   ${slUsers.value}, \n
        //   ${slFranchise.value}, \n
        //   ${slAddress.value}, \n
        //   ${slAreas.value}, \n
        //   ${slAreasId.value}, \n
        // `)
      }
    }

    const creatingNewUser = ref(false);
    const createNewUserComplete = ref(false);
    const newUserRole = ref();
    const newUserEmail = ref();
    const newUserLocationId = ref();
    const createNewUserAccount = async() => {
      creatingNewUser.value = true;
      let uid;
      try {
        uid = await createNewUser(newUserEmail.value, "password");
        console.log(`Created user: ${uid}`)
          // Send email with password reset link
      } catch (error){
          console.error(`issue creating account: ${error}`)
          return;
      }

      const delayInMilliseconds = 5000;
      setTimeout(async() => {
        await addUserLocation(uid, newUserLocationId.value, newUserRole.value)
      }, delayInMilliseconds);

      creatingNewUser.value = false;
      createNewUserComplete.value = true;
    }

    const createNewUser = async (email, password) => {
      const functions = getFunctions();
      const registerNewUserFunction = httpsCallable(functions, "registerNewUser");

      return await registerNewUserFunction({
        email: email,
        password: password,
      })
      .then(result => {
        const userId = result.data;
        return userId;
      })
      .catch(error => {
        if (error.code === 'internal') {
          console.error('Error while registering user:', error.details.detail);
        }
        // handle other types of errors if necessary
        throw new Error(`Error: ${error.details.detail}`);
      });

    };

    const findDetailsForUserEmail = ref('');
    const detailsForUserEmail = ref()
    const getUserDetailsForEmail = async() => {
      detailsForUserEmail.value = await getUserDetailsFromEmail(findDetailsForUserEmail.value);
    }

    const userRoleLocationLinking = ref('');
    const userIdForLocationLinking = ref('');
    const locationIdForLinking = ref('');
    const connectingUserToLocation = ref(false);
    const connectedUserToLocation = ref(false);
    const connectUserLocation = async() => {
      connectingUserToLocation.value = true;
      await addUserLocation(userIdForLocationLinking.value, locationIdForLinking.value, userRoleLocationLinking.value)
      
      connectingUserToLocation.value = false;
      connectedUserToLocation.value = true;
    }

    onMounted(async() => { 
      locations.value = await getLocations();
    })

    return {
      showLocationDetails,
      slLocationId,
      slLocationName,
      slFranchise,
      slAddress,
      slAreas,
      slAreasId,
      slUsers,
      zoneInput,
      locations,
      userEmail,
      locationId,
      changeLocation,
      createNewUserAccount,
      creatingNewUser,
      createNewUserComplete,
      newUserRole,
      newUserEmail,
      newUserLocationId,
      getUserDetailsForEmail,
      findDetailsForUserEmail,
      detailsForUserEmail,
      connectUserLocation,
      connectingUserToLocation,
      connectedUserToLocation,
      userRoleLocationLinking,
      userIdForLocationLinking,
      locationIdForLinking,
    }
  }
};
</script>