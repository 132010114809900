<template>
  <div class="form-template">
    <form @submit.prevent="handleSubmit">
      <div
        v-for="(field, index) in fields"
        :key="index"
        class="form-group"
      >
        <label :for="field.name">{{ field.label }}:</label>
        <input
          v-if="field.type !== 'textarea'"
          :type="field.type"
          :id="field.name"
          v-model="formData[field.name]"
          :placeholder="field.placeholder"
          class="form-input"
        />
        <textarea
          v-else
          :id="field.name"
          v-model="formData[field.name]"
          :placeholder="field.placeholder"
          class="form-input"
        ></textarea>
      </div>

      <button type="submit">{{ submitButtonText }}</button>
    </form>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "ReusableForm",
  props: {
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
    submitButtonText: {
      type: String,
      default: "Submit",
    },
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const formData = reactive(
      props.fields.reduce((acc, field) => {
        acc[field.name] = field.default || "";
        return acc;
      }, {})
    );

    const handleSubmit = () => {
      emit("submit", { ...formData });
    };

    return {
      formData,
      handleSubmit,
    };
  },
};
</script>

<style scoped>
.form-template {
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input.form-input,
textarea.form-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black; /* Ensures user input text is black */
  background-color: white; /* Optional: Ensure contrast for text */
}

textarea.form-input {
  height: 350px;
}

input.form-input::placeholder,
textarea.form-input::placeholder {
  color: #888; /* Optional: Placeholder text color */
}

button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
