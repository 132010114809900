<template>
  <!-- Raw vid size: 3072 x 1728 -->
  <div v-if="vidUrl !== undefined">
    <video 
    :src="`${vidUrl}#t=2.5`" 
    :width="width" 
    :height="height"
    :playbackRate=speed
    preload="metadata"
    controls
    muted
    ></video>      
  </div>
  <div v-else>
    <h1>Problem loading video</h1>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { useStateStore } from '@/stores/StateStore';
import { getVideoUrl } from '@/helpers';

export default {
  props: ['vidPath', 'metadata', 'width', 'height'],
  setup(props) {
    const stateStore = useStateStore();
    const speed = ref("1");

    // Watch for changes in the "playbackSpeed" state
    watch(() => stateStore.playbackSpeed, (newPlaybackSpeed) => {
      speed.value = newPlaybackSpeed;
    });

    const vidUrl = ref();
    onMounted(async () => {
      vidUrl.value = await getVideoUrl(props.vidPath, props.metadata.b2_bucket);    
    });

    return {
      speed,
      vidUrl,
    }
  }
};
</script>